// src/components/PromoButtons.js

import React from 'react';
import { FaGoogle, FaWhatsapp, FaEnvelope, FaPhone, FaFileAlt, FaMapMarkerAlt, FaPhoneAlt, FaYoutube, FaExternalLinkAlt } from 'react-icons/fa'; 
import PromoHeader from '../components/PromoHeader';

const ContactButtons = ({ 
  contactName, 
  setActiveSection,
  showCallbackButton = true,
  showGoogleReviewButtons = true,
  showWhatsappButton = true,
  showEmailButton = true,
  showPhoneButton = true,
  showQuoteButton = true, 
  showMapButton = true,    
  showYouTubeButton = false,
  showWebsiteButton = true 
}) => (
  <>
    <PromoHeader contactName="PromoCard" />
    
    {showCallbackButton && (
      <button className="button" onClick={() => setActiveSection('callbackForm')}>
        <FaPhoneAlt className="icon" /> Bel mij terug
      </button>
    )}
    {showQuoteButton && (
      <button className="button" onClick={() => setActiveSection('offerteForm')}>
        <FaFileAlt className="icon" /> Direct offerte opvragen
      </button>
    )}
    {showMapButton && (
      <a className="button" href="/map">
        <FaMapMarkerAlt className="icon" /> Bekijk voorbeelden interactief
      </a>
    )}
    {showGoogleReviewButtons && (
      <>
        <a className="button" href="https://www.google.com/search?sca_esv=7d8e94d617649ef9&sxsrf=ADLYWIJPGoCoKgEOrS35cWK2svybzXEUxg:1720615430419&q=mc+cleaning+solutions+reviews&uds=ADvngMhNdzWZDTOVWXW1ssdHSYXtBH12llLbPP3Ec70O7RoLjX63URMhO09p8UUpMdAWZnPSPftPK4HelbcztQ1wwshNd9NI88BZzXqjtxV9k9rkK80NmkyZzagkKm0A5YaGrhjut90S&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7l2GO94PKLMj9pZKX2JNAs0UBAngJNrZd-_p8x-UMsBL3CAfLyvsl-D1ao55eYT9IYT2TXo%3D&sa=X&ved=2ahUKEwi3vpn8v5yHAxVIRP4FHc_xCVMQ3PALegQIGRAE&biw=1385&bih=943&dpr=1.25" target="_blank" rel="noopener noreferrer">
          <FaGoogle className="icon" /> Google Reviews Bekijken
        </a>
        {/* <a className="button" href="https://g.page/r/CdJ_S1mABAHXEB0/review" target="_blank" rel="noopener noreferrer">
          <FaGoogle className="icon" /> Google Review Schrijven
        </a> */}
      </>
    )}
    {showWhatsappButton && (
      <a className="button" href={`https://wa.me/31648231323?text=${encodeURIComponent(`Goedendag, ik zou graag meer informatie ontvangen over uw diensten. Kan u met mij contact opnemen?`)}`}>
        <FaWhatsapp className="icon" /> Contact via WhatsApp
      </a>
    )}
    {showEmailButton && (
      <a className="button" href={`mailto:info@cmccleaningsolulutions.nl?subject=Nieuwe Aanvraag via ${contactName}&body=${encodeURIComponent(`Goedendag, Goedendag, ik zou graag meer informatie ontvangen over uw diensten. Kan u met mij contact opnemen?`)}`}>
        <FaEnvelope className="icon" /> Contact via Email
      </a>
    )}
    {showWebsiteButton && (
      <a className="button" href="https://www.mccleaningsolutions.nl" target="_blank" rel="noopener noreferrer">
        <FaExternalLinkAlt className="icon" /> Bekijk volledige Website
      </a>
    )}
    {showPhoneButton && (
      <a className="button" href="tel:+31648231323">
        <FaPhone className="icon" /> Bel Ons
      </a>
    )}
    {showYouTubeButton && (
      <a className="button" href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
        <FaYoutube className="icon" /> Bekijk ons op YouTube
      </a>
    )}
  </>
);

export default ContactButtons;
