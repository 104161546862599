// src/components/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="container">
    <h1>404 - Pagina niet gevonden</h1>
    <p>Het lijkt erop dat er iets mis is gegaan. De pagina die u zoekt, bestaat mogelijk niet of is verplaatst.</p>
    
    <p>Onze excuses voor het ongemak</p>
  </div>
);

export default NotFound;
