import React, { useState } from 'react';
import PromoButtons from '../components/PromoButtons'; 
import CallbackForm from '../components/CallbackForm';
import OfferteForm from '../components/OfferteForm';

const PromoCardPage = () => {
  const [activeSection, setActiveSection] = useState(null);

  return (
    <div className="container">
      <div className="button-container">
        {!activeSection && (
          <>
            <PromoButtons setActiveSection={setActiveSection} />
          </>
        )}
        {activeSection === 'callbackForm' && (
          <CallbackForm onCancel={() => setActiveSection(null)} />
        )}
        {activeSection === 'offerteForm' && (
          <OfferteForm onCancel={() => setActiveSection(null)} />
        )}
      </div>
    </div>
  );
};

export default PromoCardPage;
