import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Compressor from 'compressorjs'; 
import logo from '../logo.png'; 
import '../styles/App.css';

const AddMapData = () => {
  const [formData, setFormData] = useState({
    postcode: '',
    gemeente: '',
    provincie: '',
    soortPannen: '',
    kleurVerf: '',
    latitude: '',
    longitude: '',
    netnummer: ''
  });
  const [files, setFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'postcode', direction: 'ascending' });

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get('/api/mapdata');
        setAddressList(response.data);
      } catch (err) {
        alert('Failed to fetch address list.');
      }
    };

    fetchAddresses();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === 'postcode') {
      const value = e.target.value;
      if (/^\d{0,4}$/.test(value)) {
        setFormData({ ...formData, [e.target.name]: value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    let compressedFiles = [];
    let thumbnailUrls = [];

    filesArray.forEach(file => {
      new Compressor(file, {
        quality: 0.4, 
        success(result) {
          // scahlen naar 50%
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const img = new Image();
          img.src = URL.createObjectURL(result);
          img.onload = () => {
            canvas.width = img.width / 4;
            canvas.height = img.height / 4;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            canvas.toBlob(blob => {
              const compressedFile = new File([blob], file.name, { type: file.type });
              compressedFiles.push(compressedFile);
              thumbnailUrls.push(URL.createObjectURL(blob));

              if (compressedFiles.length === filesArray.length) {
                setFiles(compressedFiles);
                setThumbnails(thumbnailUrls);
              }
            }, file.type);
          };
        },
        error(err) {
          console.log(err.message);
        }
      });
    });
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
    setThumbnails(thumbnails.filter((_, i) => i !== index));
  };

  const handlePostalLookup = async () => {
    if (!formData.postcode || formData.postcode.length !== 4) {
      alert('Please provide a valid 4-digit postcode.');
      return;
    }

    try {
      const response = await axios.get(`/api/postal-lookup?postcode=${formData.postcode}`);
      if (response.status === 200 && response.data.length > 0) {
        const data = response.data[0];
        setFormData({
          ...formData,
          gemeente: data.gemeente,
          provincie: data.provincie,
          latitude: data.latitude,
          longitude: data.longitude,
          netnummer: data.netnummer
        });
      } else {
        alert('Postcode not found.');
      }
    } catch (err) {
      alert('Failed to fetch wijk. Postcode not found.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.soortPannen || !formData.kleurVerf || !formData.postcode || !formData.gemeente || !formData.provincie) {
      alert('All fields except file upload are required.');
      return;
    }

    const sasUrl = 'https://mccleaning.blob.core.windows.net/images';
    const sasToken = 'sp=racwl&st=2024-11-08T22:44:37Z&se=2025-11-09T06:44:37Z&spr=https&sv=2022-11-02&sr=c&sig=PUM4J%2F7zR82AdAhyOxLtqBjQMglRajm5HiM31klCJUM%3D';

    let uploadedFiles = [];

    try {
      for (let file of files) {
        const blobUrl = `${sasUrl}/${formData.postcode}/${file.name}?${sasToken}`;

        const response = await axios.put(blobUrl, file, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': file.type,
          },
        });

        if (response.status === 201) {
          const fileUrl = blobUrl.split('?')[0];
          uploadedFiles.push(fileUrl);
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      }

      if (uploadedFiles.length === files.length) {
        const requestData = {
          partitionKey: formData.postcode,
          rowKey: `${formData.postcode}-${Date.now()}`,
          gemeente: formData.gemeente,
          id: Date.now(),
          latitude: formData.latitude,
          longitude: formData.longitude,
          netnummer: formData.netnummer,
          postcode: formData.postcode,
          provincie: formData.provincie,
          soort: "Adres",
          woonplaats: formData.gemeente,
          fileUrls: uploadedFiles,
          dakpannen: formData.soortPannen,
          verf: formData.kleurVerf
        };

        // Verzenden naar  backend
        await axios.post('/api/mapdata', requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        alert('Alle gegevens succesvol ingevoerd!');

        handleClear();
        const updatedResponse = await axios.get('/api/mapdata');
        setAddressList(updatedResponse.data);
      } else {
        alert('Not all files were uploaded correctly.');
      }
    } catch (err) {
      alert('Failed to upload file(s) or submit data.');
    }
  };

  const handleClear = () => {
    setFormData({
      postcode: '',
      gemeente: '',
      provincie: '',
      soortPannen: '',
      kleurVerf: '',
      latitude: '',
      longitude: '',
      netnummer: ''
    });
    setFiles([]);
    setThumbnails([]);
  };

  const handleDelete = async (rowKey, partitionKey) => {
    try {
      await axios.delete('/api/mapdata', {
        data: { rowKey, partitionKey },
      });
      const updatedResponse = await axios.get('/api/mapdata');
      setAddressList(updatedResponse.data);
    } catch (err) {
      alert('Failed to delete address.');
    }
  };

  const sortedAddresses = addressList.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="container">
      <img src={logo} alt="Logo" className="logo" /> 
      <form className="callback-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Postcode (4 cijfers):</label>
          <input
            type="text"
            name="postcode"
            value={formData.postcode}
            onChange={handleChange}
            required
          />
        </div>
        <button type="button" className="button full-width" onClick={handlePostalLookup}>
          Zoek op
        </button>
        <div className="input-group">
          <label>Wijk in de gemeente:</label>
          <input
            type="text"
            name="gemeente"
            value={formData.gemeente}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>Provincie:</label>
          <input
            type="text"
            name="provincie"
            value={formData.provincie}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>Wat voor soort pannen?</label>
          <select
            name="soortPannen"
            value={formData.soortPannen}
            onChange={handleChange}
            required
          >
            <option value="">Selecteer soort pannen</option>
            <option value="Betonpannen">Betonpannen</option>
            <option value="Keramische pannen">Keramische pannen</option>
            <option value="Leien">Leien</option>
          </select>
        </div>
        <div className="input-group">
          <label>Wat voor kleur verf?</label>
          <select
            name="kleurVerf"
            value={formData.kleurVerf}
            onChange={handleChange}
            required
          >
            <option value="">Selecteer kleur verf</option>
            <option value="Zwart mat">Zwart mat</option>
            <option value="Zwart glans">Zwart glans</option>
            <option value="Antraciet mat">Antraciet mat</option>
            <option value="Antraciet glans">Antraciet glans</option>
            <option value="Ziegelrood mat">Ziegelrood mat</option>
          </select>
        </div>
        <div className="input-group">
          <label>Upload Foto's (max 3):</label>
          <label className="custom-file-upload">
            Bestanden kiezen
            <input
              type="file"
              onChange={handleFileChange}
              multiple
              accept="image/*"
            />
          </label>
        </div>
        <div className="thumbnails">
          {thumbnails.map((thumbnail, index) => (
            <div key={index} className="thumbnail">
              <img src={thumbnail} alt={`Thumbnail ${index + 1}`} />
              <button type="button" className="thumbnail-remove-button" onClick={() => removeFile(index)}>Verwijder</button>
            </div>
          ))}
        </div>
        <div className="button-group">
          <button className="button" type="button" onClick={handleClear}>CLEAR</button>
          <button className="button" type="submit">VERZEND</button>
        </div>
      </form>

      <h2>Opgeslagen Adressen</h2>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th onClick={() => requestSort('postcode')}>Postcode</th>
              <th onClick={() => requestSort('woonplaats')}>Plaats</th>
              <th onClick={() => requestSort('timestamp')}>Datum</th>
              <th>Actie</th>
            </tr>
          </thead>
          <tbody>
            {sortedAddresses.map((address) => (
              <tr key={address.rowKey}>
                <td>{address.postcode}</td>
                <td>{address.woonplaats}</td>
                <td>{new Date(address.timestamp).toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: '2-digit' })}</td>
                <td>
                  <button className="delete-button" onClick={() => handleDelete(address.rowKey, address.partitionKey)}>Verwijder</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddMapData;
