// src/components/Header.js

import React from 'react';
import logo from '../logo.png';

const Header = ({ contactName }) => (
  <div className="header">
    <img src={logo} alt="Company Logo" className="logo" />
    <p className="contact-text">
    Welkom bij MC Cleaning Solutions. Wij waarderen uw interesse in onze diensten. Klik op de knoppen hieronder voor meer informatie over onze kwalitatieve en duurzame oplossingen. Heeft u vragen of wilt u direct contact met ons opnemen? Wij staan altijd voor u klaar!
    </p>
  </div>
);

export default Header;
