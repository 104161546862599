// src/components/PhotoUpload.js

import React, { useState } from 'react';
import imageCompression from 'browser-image-compression'; 
import './compo_styles/PhotoUpload.css';  

const PhotoUpload = ({ photos, setPhotos }) => {
  const [compressing, setCompressing] = useState(false); 

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + photos.length > 4) {  
      alert('U kunt maximaal 4 foto\'s toevoegen.');
      return;
    }

    setCompressing(true); 
    const compressedPhotos = [];

    for (let file of files) {
      try {
        
        const options = {
          maxSizeMB: 2.2, 
          maxWidthOrHeight: 1000, 
          useWebWorker: true,
          initialQuality: 0.7, 
        };

        
        const compressedFile = await imageCompression(file, options);
        compressedPhotos.push(compressedFile);
      } catch (error) {
        console.error('Fout bij het comprimeren van foto:', error);
        alert('Fout bij het comprimeren van foto: ' + error.message);
      }
    }

    
    setPhotos([...photos, ...compressedPhotos]);
    setCompressing(false); 
  };

  const handleRemovePhoto = (index) => {
    const newPhotos = photos.filter((_, i) => i !== index);
    setPhotos(newPhotos);
  };

  return (
    <div className="photo-upload">
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="photo-upload-input"
      />
      <label htmlFor="photo-upload-input" className="photo-upload-label">
        Klik om bestanden toe te voegen
      </label>
      {compressing && <p>Bezig met comprimeren van foto's, een moment geduld...</p>}
      <div className="thumbnails">
        {photos.map((photo, index) => (
          <div key={index} className="thumbnail">
            <img src={URL.createObjectURL(photo)} alt={`Foto ${index + 1}`} />
            <button type="button" onClick={() => handleRemovePhoto(index)}>Verwijderen</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoUpload;
