// src/components/Header.js

import React from 'react';
import logo from '../logo.png';

const Header = ({ contactName }) => (
  <div className="header">
    <img src={logo} alt="Company Logo" className="logo" />
    <p className="contact-text">
      Aangenaam! Wij zijn MC Cleaning Solutions. Vakmanschap, kwaliteit en duurzaamheid staan bij ons hoog in het vaandel. Prettige communicatie en duidelijke afspraken, dat is bij ons normaal.
    </p>
    <p className="contact-text">
      U heeft gesproken met onze collega:<br />
      <strong>{contactName}</strong><br />
      Wij zijn u graag van dienst!
    </p>
  </div>
);

export default Header;
