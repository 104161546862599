import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import usePageTracking from './hooks/usePageTracking';
import HomePage from './pages/HomePage';
import NotFound from './components/NotFound';
import MapView from './components/MapView';
import AddMapData from './components/AddMapdata';
import './styles/App.css';
import PromoCardPage from './pages/PromoCardPage';

const App = () => {
  return (
    <Router>
      <PageTrackingWrapper>
        <Routes>
          <Route path="/" element={<NotFound />} />
          <Route path="/map" element={<MapView />} />
          <Route path="/addmapdata/marco" element={<AddMapData />} />
          <Route path="/jf" element={<HomePage user="Jimmy Fatingan" />} />
          <Route path="/mvd" element={<HomePage user="Marlon van Dam" />} />
          <Route path="/mc" element={<HomePage user="Marco Caquelein" />} />
          <Route path="/ts" element={<HomePage user="Thomas Schiffer" />} />
          <Route path="/sp" element={<HomePage user="Sven Pol" />} />
          <Route path="/mcclean/promocard" element={<PromoCardPage />} />
          <Route path="/:dynamicRoute" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </PageTrackingWrapper>
    </Router>
  );
};

const PageTrackingWrapper = ({ children }) => {
  usePageTracking();
  return children;
};

export default App;
