import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";
import { Lightbox } from 'react-modal-image';
import '../styles/App.css'; // Importeer de CSS-stijlen

L.Marker.prototype.options.icon = L.icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
});

// Voeg je JSON-gegevens hier als een constante toe
const rawLocations = [
  {
    "etag": "W/\"datetime'2024-11-12T09%3A45%3A44.3394861Z'\"",
    "partitionKey": "2461",
    "rowKey": "2461-1731404746264",
    "timestamp": "2024-11-12T09:45:44.3394861Z",
    "gemeente": "Nieuwkoop",
    "id": 1731404746264,
    "latitude": 52.164925,
    "longitude": 4.7087085,
    "netnummer": 172,
    "postcode": "2461",
    "provincie": "Zuid-Holland",
    "soort": "Adres",
    "woonplaats": "Nieuwkoop",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/2461/20220502_193007000_iOS 4.jpg, https://mccleaning.blob.core.windows.net/images/2461/20220502_193007000_iOS 2.jpg, https://mccleaning.blob.core.windows.net/images/2461/20220502_193006000_iOS 1.jpg",
    "dakpannen": "Keramische pannen",
    "verf": "Antraciet mat"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A30%3A24.8114617Z'\"",
    "partitionKey": "2551",
    "rowKey": "2551-1733841023808",
    "timestamp": "2024-12-10T14:30:24.8114617Z",
    "gemeente": "Den Haag",
    "id": 1733841023808,
    "latitude": 52.0595187,
    "longitude": 4.2363825,
    "netnummer": 70,
    "postcode": "2551",
    "provincie": "Zuid-Holland",
    "soort": "Adres",
    "woonplaats": "Den Haag",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/2551/WhatsApp Image 2024-11-12 at 11.09.10 (1).jpeg, https://mccleaning.blob.core.windows.net/images/2551/WhatsApp Image 2024-11-12 at 11.09.10.jpeg",
    "werkzaamheden": "Het reinigen en coaten van ziegelrode betonpannen"
  },
  {
    "etag": "W/\"datetime'2024-11-12T09%3A50%3A01.5002956Z'\"",
    "partitionKey": "2804",
    "rowKey": "2804-1731405003487",
    "timestamp": "2024-11-12T09:50:01.5002956Z",
    "gemeente": "Gouda",
    "id": 1731405003487,
    "latitude": 52.0310227,
    "longitude": 4.7077547,
    "netnummer": 182,
    "postcode": "2804",
    "provincie": "Zuid-Holland",
    "soort": "Adres",
    "woonplaats": "Gouda",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/2804/20220502_185059000_iOS.jpg, https://mccleaning.blob.core.windows.net/images/2804/20220502_185107000_iOS 1.jpg, https://mccleaning.blob.core.windows.net/images/2804/20220502_185107000_iOS.jpg",
    "dakpannen": "Keramische pannen",
    "verf": "Antraciet mat"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A31%3A34.6980177Z'\"",
    "partitionKey": "2983",
    "rowKey": "2983-1733841093701",
    "timestamp": "2024-12-10T14:31:34.6980177Z",
    "gemeente": "Ridderkerk",
    "id": 1733841093701,
    "latitude": 51.8865991,
    "longitude": 4.6052313,
    "netnummer": 180,
    "postcode": "2983",
    "provincie": "Zuid-Holland",
    "soort": "Adres",
    "woonplaats": "Ridderkerk",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/2983/1dc6d01c-3ea3-4883-8d10-0bd61725206b.jpg, https://mccleaning.blob.core.windows.net/images/2983/WhatsApp Image 2024-11-12 at 13.08.38 (2).jpeg, https://mccleaning.blob.core.windows.net/images/2983/WhatsApp Image 2024-11-12 at 13.08.38.jpeg",
    "werkzaamheden": "Reiniging van betonpannen, Coating antraciet"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A32%3A36.3073727Z'\"",
    "partitionKey": "3059",
    "rowKey": "3059-1733841155307",
    "timestamp": "2024-12-10T14:32:36.3073727Z",
    "gemeente": "Rotterdam",
    "id": 1733841155307,
    "latitude": 51.9829108,
    "longitude": 4.5821069,
    "netnummer": 10,
    "postcode": "3059",
    "provincie": "Zuid-Holland",
    "soort": "Adres",
    "woonplaats": "Rotterdam",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/3059/WhatsApp Image 2024-11-12 at 14.26.52 (4).jpeg, https://mccleaning.blob.core.windows.net/images/3059/WhatsApp Image 2024-11-12 at 14.26.52 (5).jpeg, https://mccleaning.blob.core.windows.net/images/3059/WhatsApp Image 2024-11-12 at 14.26.52 (6).jpeg",
    "werkzaamheden": "Reiniging van de dakpannen en coating in Glans zwart"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A34%3A04.1415704Z'\"",
    "partitionKey": "3255",
    "rowKey": "3255-1733841243144",
    "timestamp": "2024-12-10T14:34:04.1415704Z",
    "gemeente": "Oostflakkee",
    "id": 1733841243144,
    "latitude": 51.6909767,
    "longitude": 4.2126832,
    "netnummer": 187,
    "postcode": "3255",
    "provincie": "Zuid-Holland",
    "soort": "Adres",
    "woonplaats": "Oostflakkee",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/3255/WhatsApp Image 2024-11-12 at 14.26.52 (4).jpeg",
    "werkzaamheden": "Gevel wit gekleurd en keramisch dak gereinigd / ziegelrood gecoat. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A35%3A37.3086969Z'\"",
    "partitionKey": "3437",
    "rowKey": "3437-1733841336308",
    "timestamp": "2024-12-10T14:35:37.3086969Z",
    "gemeente": "Nieuwegein",
    "id": 1733841336308,
    "latitude": 52.05304,
    "longitude": 5.0729949,
    "netnummer": 30,
    "postcode": "3437",
    "provincie": "Utrecht",
    "soort": "Adres",
    "woonplaats": "Nieuwegein",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/3437/WhatsApp Image 2024-11-12 at 14.24.51 (1).jpeg, https://mccleaning.blob.core.windows.net/images/3437/WhatsApp Image 2024-11-12 at 14.24.52 (2).jpeg, https://mccleaning.blob.core.windows.net/images/3437/WhatsApp Image 2024-11-12 at 14.24.52 (3).jpeg",
    "werkzaamheden": "Betonpannen gereinigd. Coating Antraciet"
  },
  {
    "etag": "W/\"datetime'2024-11-12T08%3A31%3A49.7419579Z'\"",
    "partitionKey": "3645",
    "rowKey": "3645-1731400311682",
    "timestamp": "2024-11-12T08:31:49.7419579Z",
    "gemeente": "De Ronde Venen",
    "id": 1731400311682,
    "latitude": 52.2150916,
    "longitude": 4.9348068,
    "netnummer": 297,
    "postcode": "3645",
    "provincie": "Utrecht",
    "soort": "Adres",
    "woonplaats": "De Ronde Venen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/3645/20220617_102659.jpg, https://mccleaning.blob.core.windows.net/images/3645/20220617_185700.jpg, https://mccleaning.blob.core.windows.net/images/3645/20220617_111024.jpg",
    "dakpannen": "Betonpannen",
    "verf": "Zwart mat"
  },
  {
    "etag": "W/\"datetime'2024-11-12T09%3A53%3A04.3676234Z'\"",
    "partitionKey": "4307",
    "rowKey": "4307-1731405186397",
    "timestamp": "2024-11-12T09:53:04.3676234Z",
    "gemeente": "Schouwen-Duiveland",
    "id": 1731405186397,
    "latitude": 51.6501195,
    "longitude": 4.0375444,
    "netnummer": 111,
    "postcode": "4307",
    "provincie": "Zeeland",
    "soort": "Adres",
    "woonplaats": "Schouwen-Duiveland",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/4307/WhatsApp Image 2024-11-12 at 10.51.41.jpeg, https://mccleaning.blob.core.windows.net/images/4307/WhatsApp Image 2024-11-12 at 10.51.41 (1).jpeg, https://mccleaning.blob.core.windows.net/images/4307/WhatsApp Image 2024-11-12 at 10.51.41 (2).jpeg",
    "dakpannen": "Betonpannen",
    "verf": "Antraciet mat"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A36%3A16.6999228Z'\"",
    "partitionKey": "4384",
    "rowKey": "4384-1733841375702",
    "timestamp": "2024-12-10T14:36:16.6999228Z",
    "gemeente": "Vlissingen",
    "id": 1733841375702,
    "latitude": 51.4588944,
    "longitude": 3.5455371,
    "netnummer": 118,
    "postcode": "4384",
    "provincie": "Zeeland",
    "soort": "Adres",
    "woonplaats": "Vlissingen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/4384/6c8173fc-91ac-4323-8f2c-ec18f0e11b9d.jpg, https://mccleaning.blob.core.windows.net/images/4384/WhatsApp Image 2024-11-12 at 14.14.33 (4).jpeg, https://mccleaning.blob.core.windows.net/images/4384/WhatsApp Image 2024-11-12 at 14.14.34 (7).jpeg",
    "werkzaamheden": "Betonpannen gereinigd en antraciet gecoat"
  },
  {
    "etag": "W/\"datetime'2024-11-12T09%3A56%3A08.742055Z'\"",
    "partitionKey": "4437",
    "rowKey": "4437-1731405370737",
    "timestamp": "2024-11-12T09:56:08.742055Z",
    "gemeente": "Borsele",
    "id": 1731405370737,
    "latitude": 51.3891882,
    "longitude": 3.8160041,
    "netnummer": 113,
    "postcode": "4437",
    "provincie": "Zeeland",
    "soort": "Adres",
    "woonplaats": "Borsele",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/4437/WhatsApp Image 2024-11-12 at 10.54.57 (2).jpeg, https://mccleaning.blob.core.windows.net/images/4437/WhatsApp Image 2024-11-12 at 10.54.57.jpeg, https://mccleaning.blob.core.windows.net/images/4437/WhatsApp Image 2024-11-12 at 10.54.57 (1).jpeg",
    "dakpannen": "Betonpannen",
    "verf": "Ziegelrood mat"
  },
  {
    "etag": "W/\"datetime'2024-11-21T13%3A55%3A31.4176094Z'\"",
    "partitionKey": "4817",
    "rowKey": "4817-1732197331218",
    "timestamp": "2024-11-21T13:55:31.4176094Z",
    "gemeente": "Breda",
    "id": 1732197331218,
    "latitude": 51.5900222,
    "longitude": 4.8175264,
    "netnummer": 76,
    "postcode": "4817",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Breda",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/4817/20240828_122913.jpg, https://mccleaning.blob.core.windows.net/images/4817/20240828_121118.jpg",
    "werkzaamheden": "Iets"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A38%3A19.2190633Z'\"",
    "partitionKey": "4817",
    "rowKey": "4817-1733841498223",
    "timestamp": "2024-12-10T14:38:19.2190633Z",
    "gemeente": "Breda",
    "id": 1733841498223,
    "latitude": 51.5900222,
    "longitude": 4.8175264,
    "netnummer": 76,
    "postcode": "4817",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Breda",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/4817/WhatsApp Image 2024-11-12 at 13.37.20.jpeg, https://mccleaning.blob.core.windows.net/images/4817/WhatsApp Image 2024-11-12 at 13.37.21 (1).jpeg, https://mccleaning.blob.core.windows.net/images/4817/WhatsApp Image 2024-11-12 at 13.37.21.jpeg",
    "werkzaamheden": "Betonpannen gereinigd en antraciet gecoat"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A39%3A06.4857283Z'\"",
    "partitionKey": "4854",
    "rowKey": "4854-1733841545488",
    "timestamp": "2024-12-10T14:39:06.4857283Z",
    "gemeente": "Breda",
    "id": 1733841545488,
    "latitude": 51.5668739,
    "longitude": 4.8308114,
    "netnummer": 161,
    "postcode": "4854",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Breda",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/4854/WhatsApp Image 2024-11-12 at 13.55.28 (1).jpeg, https://mccleaning.blob.core.windows.net/images/4854/WhatsApp Image 2024-11-12 at 13.55.28 (2).jpeg, https://mccleaning.blob.core.windows.net/images/4854/WhatsApp Image 2024-11-12 at 13.55.28.jpeg",
    "werkzaamheden": "Betonpannen gereinigd en antraciet gecoat. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A39%3A41.492486Z'\"",
    "partitionKey": "4884",
    "rowKey": "4884-1733841580496",
    "timestamp": "2024-12-10T14:39:41.492486Z",
    "gemeente": "Zundert",
    "id": 1733841580496,
    "latitude": 51.4558841,
    "longitude": 4.6431964,
    "netnummer": 76,
    "postcode": "4884",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Zundert",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/4884/WhatsApp Image 2024-11-12 at 13.33.34 (1).jpeg, https://mccleaning.blob.core.windows.net/images/4884/WhatsApp Image 2024-11-12 at 13.33.34 (2).jpeg, https://mccleaning.blob.core.windows.net/images/4884/WhatsApp Image 2024-11-12 at 13.33.34.jpeg",
    "werkzaamheden": "Betonpannen gereinigd en zwart gecoat"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A40%3A19.8972789Z'\"",
    "partitionKey": "5051",
    "rowKey": "5051-1733841618900",
    "timestamp": "2024-12-10T14:40:19.8972789Z",
    "gemeente": "Goirle",
    "id": 1733841618900,
    "latitude": 51.5015547,
    "longitude": 5.0411917,
    "netnummer": 13,
    "postcode": "5051",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Goirle",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5051/WhatsApp Image 2024-11-12 at 14.19.52 (2).jpeg, https://mccleaning.blob.core.windows.net/images/5051/WhatsApp Image 2024-11-12 at 14.19.52 (3).jpeg, https://mccleaning.blob.core.windows.net/images/5051/WhatsApp Image 2024-11-12 at 14.19.53 (1).jpeg",
    "werkzaamheden": "Dit pand is voorzien van keramische pannen. Deze hebben wij gereinigd en voorzien van een antraciet coating. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A41%3A22.6450022Z'\"",
    "partitionKey": "5061",
    "rowKey": "5061-1733841681647",
    "timestamp": "2024-12-10T14:41:22.6450022Z",
    "gemeente": "Oisterwijk",
    "id": 1733841681647,
    "latitude": 51.5873331,
    "longitude": 5.1900183,
    "netnummer": 13,
    "postcode": "5061",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Oisterwijk",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5061/WhatsApp Image 2024-11-12 at 11.03.55 (1).jpeg, https://mccleaning.blob.core.windows.net/images/5061/WhatsApp Image 2024-11-12 at 11.03.55.jpeg",
    "werkzaamheden": "Deze betonpannen hebben we gereinigd en voorzien van een antraciet coating. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A43%3A03.3337959Z'\"",
    "partitionKey": "5062",
    "rowKey": "5062-1733841782336",
    "timestamp": "2024-12-10T14:43:03.3337959Z",
    "gemeente": "Oisterwijk",
    "id": 1733841782336,
    "latitude": 51.562502,
    "longitude": 5.2146593,
    "netnummer": 13,
    "postcode": "5062",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Oisterwijk",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5062/WhatsApp Image 2024-11-12 at 14.23.53 (1).jpeg, https://mccleaning.blob.core.windows.net/images/5062/WhatsApp Image 2024-11-12 at 14.23.54 (2).jpeg, https://mccleaning.blob.core.windows.net/images/5062/WhatsApp Image 2024-11-12 at 14.23.54 (3).jpeg",
    "werkzaamheden": "Betonpannen gereinigd en gecoat in het antraciet"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A44%3A21.9563516Z'\"",
    "partitionKey": "5121",
    "rowKey": "5121-1733841860958",
    "timestamp": "2024-12-10T14:44:21.9563516Z",
    "gemeente": "Gilze en Rijen",
    "id": 1733841860958,
    "latitude": 51.5747499,
    "longitude": 4.9313034,
    "netnummer": 161,
    "postcode": "5121",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Gilze en Rijen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5121/WhatsApp Image 2024-11-12 at 13.42.03 (1).jpeg, https://mccleaning.blob.core.windows.net/images/5121/WhatsApp Image 2024-11-12 at 13.42.03 (2).jpeg",
    "werkzaamheden": "De betonpannen van dit pand zijn gereinigd. Tevens hebben we deze voorzien van een verse coating antraciet. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A45%3A16.6617312Z'\"",
    "partitionKey": "5121",
    "rowKey": "5121-1733841915663",
    "timestamp": "2024-12-10T14:45:16.6617312Z",
    "gemeente": "Gilze en Rijen",
    "id": 1733841915663,
    "latitude": 51.5747499,
    "longitude": 4.9313034,
    "netnummer": 161,
    "postcode": "5121",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Gilze en Rijen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5121/WhatsApp Image 2024-11-12 at 13.15.23 (2).jpeg, https://mccleaning.blob.core.windows.net/images/5121/WhatsApp Image 2024-11-12 at 13.15.23.jpeg",
    "werkzaamheden": "Deze betonpannen hebben we voorzien van mat zwarte coating na het reinigen. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A48%3A11.7984841Z'\"",
    "partitionKey": "5124",
    "rowKey": "5124-1733842090799",
    "timestamp": "2024-12-10T14:48:11.7984841Z",
    "gemeente": "Gilze en Rijen",
    "id": 1733842090799,
    "latitude": 51.5728921,
    "longitude": 4.8820195,
    "netnummer": 161,
    "postcode": "5124",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Gilze en Rijen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5124/WhatsApp Image 2024-11-12 at 14.07.12.jpeg, https://mccleaning.blob.core.windows.net/images/5124/WhatsApp Image 2024-11-12 at 14.07.13 (1).jpeg, https://mccleaning.blob.core.windows.net/images/5124/WhatsApp Image 2024-11-12 at 14.07.13.jpeg",
    "werkzaamheden": "Deze betonpannen waren flink vies. Na een stevige reiniging hebben we deze voorzien van een zwarte coating. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A46%3A48.1628427Z'\"",
    "partitionKey": "5125",
    "rowKey": "5125-1733842007164",
    "timestamp": "2024-12-10T14:46:48.1628427Z",
    "gemeente": "Gilze en Rijen",
    "id": 1733842007164,
    "latitude": 51.5722857,
    "longitude": 4.9549864,
    "netnummer": 161,
    "postcode": "5125",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Gilze en Rijen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5125/WhatsApp Image 2024-11-12 at 13.49.08 (1).jpeg, https://mccleaning.blob.core.windows.net/images/5125/WhatsApp Image 2024-11-12 at 13.49.08.jpeg, https://mccleaning.blob.core.windows.net/images/5125/WhatsApp Image 2024-11-12 at 13.49.09.jpeg",
    "werkzaamheden": "Deze betonpannen hebben een mooie laag mat zwarte coating gekregen na een reiniging. "
  },
  {
    "etag": "W/\"datetime'2024-11-12T09%3A31%3A08.4770184Z'\"",
    "partitionKey": "5131",
    "rowKey": "5131-1731403868869",
    "timestamp": "2024-11-12T09:31:08.4770184Z",
    "gemeente": "Alphen-Chaam",
    "id": 1731403868869,
    "latitude": 51.4823836,
    "longitude": 4.9533065,
    "netnummer": 13,
    "postcode": "5131",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Alphen-Chaam",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5131/20220420_111108.jpg, https://mccleaning.blob.core.windows.net/images/5131/20220420_102406.jpg, https://mccleaning.blob.core.windows.net/images/5131/20220420_153632.jpg",
    "dakpannen": "Betonpannen",
    "verf": "Antraciet mat"
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A48%3A50.9328489Z'\"",
    "partitionKey": "5283",
    "rowKey": "5283-1733842129934",
    "timestamp": "2024-12-10T14:48:50.9328489Z",
    "gemeente": "Boxtel",
    "id": 1733842129934,
    "latitude": 51.5963601,
    "longitude": 5.341663,
    "netnummer": 411,
    "postcode": "5283",
    "provincie": "Noord-Brabant",
    "soort": "Adres",
    "woonplaats": "Boxtel",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/5283/WhatsApp Image 2024-11-12 at 11.06.11.jpeg, https://mccleaning.blob.core.windows.net/images/5283/WhatsApp Image 2024-11-12 at 11.06.12.jpeg",
    "werkzaamheden": "Deze betonpannen zijn voorzien van een laag antraciet coating. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A51%3A15.3863235Z'\"",
    "partitionKey": "6164",
    "rowKey": "6164-1733842274385",
    "timestamp": "2024-12-10T14:51:15.3863235Z",
    "gemeente": "Sittard-Geleen",
    "id": 1733842274385,
    "latitude": 50.9531912,
    "longitude": 5.8293893,
    "netnummer": 46,
    "postcode": "6164",
    "provincie": "Limburg",
    "soort": "Adres",
    "woonplaats": "Sittard-Geleen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/6164/WhatsApp Image 2024-11-12 at 14.35.21 (3).jpeg, https://mccleaning.blob.core.windows.net/images/6164/WhatsApp Image 2024-11-12 at 14.35.21 (4).jpeg, https://mccleaning.blob.core.windows.net/images/6164/WhatsApp Image 2024-11-12 at 14.35.21 (5).jpeg",
    "werkzaamheden": "Deze betonpannen hebben we ontdaan van flink wat mos. Daarna gecoat in het antraciet. "
  },
  {
    "etag": "W/\"datetime'2024-12-10T14%3A51%3A49.6175373Z'\"",
    "partitionKey": "7731",
    "rowKey": "7731-1733842308618",
    "timestamp": "2024-12-10T14:51:49.6175373Z",
    "gemeente": "Ommen",
    "id": 1733842308618,
    "latitude": 52.5248059,
    "longitude": 6.4262926,
    "netnummer": 529,
    "postcode": "7731",
    "provincie": "Overijssel",
    "soort": "Adres",
    "woonplaats": "Ommen",
    "fileUrls": "https://mccleaning.blob.core.windows.net/images/7731/WhatsApp Image 2024-11-12 at 14.32.40 (1).jpeg, https://mccleaning.blob.core.windows.net/images/7731/WhatsApp Image 2024-11-12 at 14.32.41 (2).jpeg, https://mccleaning.blob.core.windows.net/images/7731/WhatsApp Image 2024-11-12 at 14.32.41 (3).jpeg",
    "werkzaamheden": "Deze betonpannen hebben we gecoat in zieglrood"
  }
];

const MapView = () => {
  const [locations, setLocations] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const initialPosition = [51.5719, 4.7683];

  useEffect(() => {
    // Formatteer de data vanuit de constante rawLocations
    const formattedLocations = rawLocations.map(item => ({
      name: `${item.gemeente} - ${item.postcode}`,
      coords: [item.latitude, item.longitude],
      info: `Werkzaamheden: ${item.werkzaamheden || 'Onbekend'}<br/><br/>Wij publiceren geen adresgegevens van onze klanten. De aangegeven locatie is daarom bij benadering.`,
      images: item.fileUrls ? item.fileUrls.split(', ') : []
    }));

    setLocations(formattedLocations);
  }, []);

  const openInfoPopup = () => {
    alert('Indien u geïnteresseerd bent in een bezoek aan een van onze projecten, nemen wij graag contact op met de betreffende klant om hun toestemming te verkrijgen voor het delen van het exacte adres.');
  };

  return (
    <>
      <MapContainer center={initialPosition} zoom={8} className="map-container">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker key={index} position={location.coords}>
            <Popup>
              <h3>{location.name}</h3>
              {location.images.map((image, idx) => (
                <img
                  key={idx}
                  src={image}
                  alt={`${location.name} ${idx + 1}`}
                  className="fixed-size-image"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
              <p dangerouslySetInnerHTML={{ __html: location.info }}></p>
              <button
                onClick={openInfoPopup}
                style={{
                  border: 'none',
                  background: 'none',
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                Meer informatie
              </button>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      {selectedImage && (
        <Lightbox
          medium={selectedImage}
          large={selectedImage}
          alt="Afbeelding"
          onClose={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};

export default MapView;
