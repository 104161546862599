// src/pages/HomePage.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import ContactButtons from '../components/ContactButtons';
import PromoButtons from '../components/PromoButtons'; 
import CallbackForm from '../components/CallbackForm';
import OfferteForm from '../components/OfferteForm';
import { useParams } from 'react-router-dom';

const HomePage = ({ user, showPromoButtons }) => {
  const [activeSection, setActiveSection] = useState(null);
  const { dynamicRoute } = useParams();

  const getContactName = (suffix) => {
    switch (suffix) {
      case 'jf': return 'Jimmy Fatingan';
      case 'mvd': return 'Marlon van Dam';
      case 'mc': return 'Marco Caquelein';
      case 'ts': return 'Thomas Schiffer';
      case 'sp': return 'Sven Pol';
      default: return 'onbekend';
    }
  };
  
  const contactName = user || getContactName(dynamicRoute);

  return (
    <div className="container">
      <Header contactName={contactName} />
      <div className="button-container">
        {!activeSection && (
          <>
            {showPromoButtons ? (
              <PromoButtons contactName={contactName} setActiveSection={setActiveSection} />
            ) : (
              <ContactButtons contactName={contactName} setActiveSection={setActiveSection} />
            )}
          </>
        )}
        {activeSection === 'callbackForm' && (
          <CallbackForm contactName={contactName} onCancel={() => setActiveSection(null)} />
        )}
        {activeSection === 'offerteForm' && (
          <OfferteForm onCancel={() => setActiveSection(null)} />
        )}
      </div>
    </div>
  );
};

export default HomePage;
